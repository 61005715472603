import React, { useState, useEffect } from 'react';

// Define image data
const firstRowData = [
    { src: '/assets/images/welcomev2/image01.jpg', alt: 'Teacher 1', type: 'image-card' },
    {
        src: "/assets/images/welcomev2/image01.jpg",
        alt: "Teacher 1",
        profileImage: "/assets/images/welcomev2/image01.jpg",
        name: "Mr. Khorshed Alam",
        designation: "Teacher, Ideal Academic School",
        testimonial: "Love using Edutechs. Makes managing my lessons and connecting with students super easy!",
        type: 'testimonial-card'
    },
    { src: '/assets/images/welcomev2/image05.jpg', alt: 'Teacher 5', type: 'image-card' },
    {
        src: "/assets/images/welcomev2/image03.jpg",
        alt: "Teacher 3",
        profileImage: "/assets/images/welcomev2/image01.jpg",
        name: "Mr. Khorshed Alam",
        designation: "Teacher, Ideal Academic School",
        testimonial: "Love using Edutechs. Makes managing my lessons and connecting with students super easy!",
        type: 'testimonial-card'
    },
    { src: '/assets/images/welcomev2/image04.jpg', alt: 'Teacher 11', type: 'image-card' },
    {
        src: "/assets/images/welcomev2/image07.jpg",
        alt: "Teacher 3",
        profileImage: "/assets/images/welcomev2/image01.jpg",
        name: "Mr. Khorshed Alam",
        designation: "Teacher, Ideal Academic School",
        testimonial: "Love using Edutechs. Makes managing my lessons and connecting with students super easy!",
        type: 'testimonial-card'
    },

];

const secondRowData = [
    {
        src: "/assets/images/welcomev2/image01.jpg",
        alt: "Teacher 1",
        profileImage: "/assets/images/welcomev2/image01.jpg",
        name: "Mr. Khorshed Alam",
        designation: "Teacher, Ideal Academic School",
        testimonial: "Love using Edutechs. Makes managing my lessons and connecting with students super easy!",
        type: 'testimonial-card'
    },
    { src: '/assets/images/welcomev2/image11.jpg', alt: 'Teacher 5', type: 'image-card' },
    {
        src: "/assets/images/welcomev2/image07.jpg",
        alt: "Teacher 3",
        profileImage: "/assets/images/welcomev2/image01.jpg",
        name: "Mr. Khorshed Alam",
        designation: "Teacher, Ideal Academic School",
        testimonial: "Love using Edutechs. Makes managing my lessons and connecting with students super easy!",
        type: 'testimonial-card'
    },
    { src: '/assets/images/welcomev2/image06.jpg', alt: 'Teacher 10', type: 'image-card' },
    {
        src: "/assets/images/welcomev2/image03.jpg",
        alt: "Teacher 3",
        profileImage: "/assets/images/welcomev2/image01.jpg",
        name: "Mr. Khorshed Alam",
        designation: "Teacher, Ideal Academic School",
        testimonial: "Love using Edutechs. Makes managing my lessons and connecting with students super easy!",
        type: 'testimonial-card'
    },
    
];

const CommunitySection = () => {


    // State to keep track of window width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    console.log('windowWidth', windowWidth)

    // Effect to update window width on resize
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const getTransformStyle = (index) => {

        if (index === 0) {
            return { transform: `translateX(-50px)` }
        }
        if (index === 1) {

            return { transform: `translateX(-30px)` }
        }
        if (index === 2) {
            return { transform: `translateX(-10px)` }
        }
        if (index === 3) {
            return { transform: `translateX(10px)` }
        }
        if (index === 4) {
            return { transform: `translateX(30px)` }
        }
        if (index === 5) {
            return { transform: `translateX(50px)` }
        }

        if (index === 6) {
            return { transform: `translateX(-160px)` }
        }
        if (index === 7) {

            return { transform: `translateX(-130px)` }
        }
        if (index === 8) {
            return { transform: `translateX(-100px)` }
        }
        if (index === 9) {
            return { transform: `translateX(10px)` }
        }
        if (index === 10) {
            return { transform: `translateX(30px)` }
        }
        if (index === 11) {
            return { transform: `translateX(50px)` }
        }

        return {};
    };


    return (
        <section className="gx-our-community-section gx-section-padding">
            <div className="gx-landing-container">
                <div className="gx-section-title">
                    <img src="/assets/images/welcomev2/vector6.png" alt="Love" />
                    <h1>Our community is our <span>superpower</span></h1>
                </div>
            </div>

            <div className="gx-images-flex-container">
                {/* First Row */}
                <div className="gx-image-row">
                    {firstRowData.map((item, index) => (
                        item.type === 'image-card' ? (
                            <div key={index} className={`gx-image-wrapper gx-community-${index + 1}`} style={getTransformStyle(index)}>
                                <img
                                    src={item.src}
                                    alt={item.alt}
                                    className="gx-rounded-images"
                                    // style={{ width: imageSize, height: imageSize }}
                                />
                            </div>
                        ) : (
                            <div key={index} className={`gx-card-wrapper gx-text-box-card gx-community-${index + 1}`} style={getTransformStyle(index)}>
                                <div className="gx-profile-wrapper">
                                    <img src={item.profileImage} alt={item.name} className="gx-profile-image" />
                                    <div className="gx-profile-info">
                                        <h5>{item.name}</h5>
                                        <p>{item.designation}</p>
                                    </div>
                                </div>
                                <div className="gx-testimonial">
                                    <p>{item.testimonial}</p>
                                </div>
                            </div>
                        )
                    ))}
                </div>

                {/* Second Row */}
                <div className="gx-image-row gx-mt-5">
                    {secondRowData.map((item, index) => (
                        item.type === 'image-card' ? (
                            <div key={index} className={`gx-image-wrapper gx-community-${index + 6}`} style={getTransformStyle(index)}>
                                <img
                                    src={item.src}
                                    alt={item.alt}
                                    className="gx-rounded-images"
                                    // style={{ width: imageSize, height: imageSize }}
                                />
                            </div>
                        ) : (
                            <div key={index} className={`gx-card-wrapper gx-text-box-card gx-community-${index + 6}`} style={getTransformStyle(index)}>
                                <div className="gx-profile-wrapper">
                                    <img src={item.profileImage} alt={item.name} className="gx-profile-image" />
                                    <div className="gx-profile-info">
                                        <h5>{item.name}</h5>
                                        <p>{item.designation}</p>
                                    </div>
                                </div>
                                <div className="gx-testimonial">
                                    <p>{item.testimonial}</p>
                                </div>
                            </div>
                        )
                    ))}
                </div>
            </div>
        </section>
    );
};

export default CommunitySection;
