import firebase from "firebase";

// Initialize Firebase
const config = {
    apiKey: "AIzaSyCBGF5jQfpK_yktdnUs8a80XPn0MLxSt9Y",
    authDomain: "edutechs.app",
    databaseURL: "https://edutech-2121.firebaseio.com",
    projectId: "edutech-2121",
    storageBucket: "edutech-2121.appspot.com",
    messagingSenderId: "981500378332",
    appId: "1:981500378332:web:d1e03f7398e7c940664819",
    measurementId: "G-6DM8ETFP1M"
};

firebase.initializeApp(config);

const auth                          = firebase.auth();
const database                      = firebase.database();


export {
  database,
  auth
};
