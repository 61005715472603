import React, { useState } from 'react'
import WelcomeHeader from '../WelcomeHeader'
// import VideoSection from './VideoSection'
import VideoCardSection from './VideoSection'
import FutureProofSection from './FutureProofSection'
import { Collapse } from 'antd'
import Footer from '../Footer'
import { CloseOutlined, PlusOutlined } from '@ant-design/icons'

const AiPage = () => {

    const [activeKey, setActiveKey] = useState([]);

    const handleIcon = (key) => {
        return activeKey?.includes(key) ? <CloseOutlined /> : <PlusOutlined />;
    };

    const onChange = (key) => {
        setActiveKey(key);
    };

    return (
        <div className="gx-welcome-pagev2 gx-ai-page">
            <WelcomeHeader />

            <main>

                <div className="gx-landing-container">
                    <FutureProofSection />
                    <VideoCardSection />

                </div>



                <section className="gx-faq-section gx-section-padding">
                    <div className="gx-landing-container">
                        <div className="gx-faq-wrapper">
                            <h2 className='gx-mb-3'>FAQs</h2>
                            <Collapse
                                ghost
                                expandIconPosition="end"
                                bordered={false}
                                activeKey={activeKey}
                                onChange={onChange}
                                expandIcon={({ panelKey }) => handleIcon(panelKey)}
                                className="gx-faq-custom-collapse"
                            >






                                <Collapse.Panel header="How do I start integrating Al into my school?" key="1">
                                    <p>Book a demo with us. Our team will guide you through the setup and integration process, ensuring a smooth transition to the enhanced learning
                                        environment.</p>
                                </Collapse.Panel>
                                <Collapse.Panel header="Can I try Edutechs Al for free?" key="2">
                                    <p>Yes, we offer a demo or trial period for schools to explore the Al capabilities before making a commitment. Please contact us for more details.</p>
                                </Collapse.Panel>
                                <Collapse.Panel header="How does the Al tutoring feature work?" key="3">
                                    <p>The Al tutor adapts to each student's unique learning style, providing personalized assistance and support whenever needed, allowing students to
                                        ask questions and receive instant, accurate responses.</p>
                                </Collapse.Panel>
                                <Collapse.Panel header="Can Al-generated assessments replace traditional evaluations?" key="4">
                                   <p>Our Al-generated assessments are designed to be used alongside traditional exams and assessments.
                                   It specifically designed to target students' weak areas and provide instant feedback, making evaluations more personalized, efficient and fast.</p>
                                </Collapse.Panel>
                                <Collapse.Panel header="What types of learning materials can I generate?" key="5">
                                    <p>Our Al can create a variety of learning materials, including explanatory videos, animations, diagrams, flowcharts, and more, all generated instantly
                                        based on simple prompts from teachers.</p>
                                </Collapse.Panel>
                                <Collapse.Panel header="How does the conflict detection feature ensure student safety?" key="6">
                                    <p>Our Al-driven surveillance system monitors the campus in real-time to detect issues like ragging, bullying, physical violence, and sexual
                                        harassment. By analyzing behavior patterns, it alerts school authorities immediately upon identifying any suspicious activities, enabling swift
                                        intervention and creating a safer learning environment.</p>
                                </Collapse.Panel>
                                <Collapse.Panel header="What tasks can the action model perform for educators?" key="7">
                                    <p>The action model automates various administrative tasks, allowing teachers to focus on instruction while handling scheduling, reminders, and
                                        other routine tasks seamlessly in the background.</p>
                                </Collapse.Panel>
                                <Collapse.Panel header="What support is available after implementation?" key="8">
                                    <p>Yes. You can revert back to the free plan whenever you want. However, please note that you will lose access to the exclusive features of the pro plan.</p>
                                </Collapse.Panel>
                                <Collapse.Panel header="Can I upgrade later on in the future after I start using it?" key="9">
                                    <p>We provide ongoing support through our dedicated customer service team, training sessions, and resources to help educators maximize the
                                        benefits of Al in their classrooms.</p>
                                </Collapse.Panel>
                            </Collapse>
                        </div>
                    </div>
                </section>


            </main>

            <Footer />
        </div>
    )
}

export default AiPage