

import crypto from "crypto";
import { message } from "antd";
import { includes, get, pullAllBy } from "lodash-es";
import {database} from "../firebase/firebase";
import { currency_code } from "../Common-Currency";
import moment from "moment";


export function isMobile() {
    const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return regex.test(navigator.userAgent);
}
  


export function fixBangladeshPhoneNumbers(inputNumber){

    if (inputNumber){
        let newInput = [];
        for (let x of String(inputNumber)){
            if (isNumber(x)){
                newInput.push(x);
            }
        }

        inputNumber = newInput.join('');

        if ( String(inputNumber).length === 10 && isNumber(inputNumber)){
            inputNumber = `+880${inputNumber}`;
        }
        else if ( String(inputNumber).length === 11 && isNumber(inputNumber)){
            inputNumber = `+88${inputNumber}`;
        }
        else if ((String(inputNumber)).slice(0,3) === '880'){
            inputNumber = '+' + String(inputNumber);
        }
        else if ( String(inputNumber).length === 13 && (String(inputNumber)).slice(0,2) === '88'){
            inputNumber = '+880' + ((String(inputNumber)).substr(2)).slice(0,-1);
        }

        return inputNumber
    }
    else{
        return inputNumber
    }
}

export function fixBangladeshPhoneNumbersV2(inputNumber){

    if (inputNumber){
        let newInput = [];
        for (let x of String(inputNumber)){
            if (isNumber(x)){
                newInput.push(x);
            }
        }

        inputNumber = newInput.join('');

        if ( String(inputNumber).length === 10 && isNumber(inputNumber)){
            inputNumber = `+880${inputNumber}`;
        }
        else if ( String(inputNumber).length === 11 && isNumber(inputNumber)){
            inputNumber = `+88${inputNumber}`;
        }
        else if ((String(inputNumber)).slice(0,3) === '880'){
            inputNumber = '+' + String(inputNumber);
        }
        return inputNumber.substring(3);
    }
    else{
        return inputNumber
    }
}

export function generateKey(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function getAccountPathPrefix(db, activeTUID){
    let proffession     = get(db, ['PublicInfo', 'UserProffession'], undefined);
    let pathPrefix      = [];

    if (!proffession){
        message.error('Invalid Account Proffession - Please relog back in and try again')
        return undefined
    }

    if (proffession === 'Admin'){
        pathPrefix = [...["VolatileLocalData", "Admined"], ...[activeTUID ? activeTUID : []]];
    }

    if (proffession === 'TeacherAssistant'){
        pathPrefix = [...["VolatileLocalData", "Moderating"], ...[activeTUID ? activeTUID : []]];
    }

    if (proffession === 'Teacher'){
        pathPrefix = [];
    }

    return pathPrefix
}

export function generateNumberKey(length) {
    var result           = '';
    var characters       = '0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function checkIfIdClashes (id, db){

    let valid       = true;

    if (db['PublicInfo']['UserProffession'] === 'Teacher'){
        let userClass = get(db, ['UserClass'], {});
    
        for (let grade in userClass){
            for (let subject in userClass[grade]){
                for (let batch in userClass[grade][subject]['Streams']){
    
                    let acceptedStudents = get(userClass[grade][subject]['Streams'], [batch, 'AcceptedStudents'], {});
    
                    for (let oneSUID in acceptedStudents){
                        if (acceptedStudents[oneSUID]['customID']){
                            let this_customID = acceptedStudents[oneSUID]['customID'];
                            if (`ID-${id}` === this_customID) valid = false;
                        }
    
                    }
    
                }
            }
        }
    }
    else if (db['PublicInfo']['UserProffession'] === 'Admin'){

        let allTeachers         = get(db, ['VolatileLocalData', 'Admined'], {});

        for (let tuid in allTeachers){
            let userClass = allTeachers[tuid]['UserClass'];
            for (let grade in userClass){
                for (let subject in userClass[grade]){
                    for (let batch in userClass[grade][subject]['Streams']){
        
                        let acceptedStudents = get(userClass[grade][subject]['Streams'], [batch, 'AcceptedStudents'], {});
        
                        for (let oneSUID in acceptedStudents){
                            if (acceptedStudents[oneSUID]['customID']){
                                let this_customID = acceptedStudents[oneSUID]['customID'];
                                if (`ID-${id}` === this_customID) valid = false;
                            }
        
                        }
        
                    }
                }
            }
        }
    }

    return valid

}

export function updateLabel ({db, current, updateTo, t}){
    const manageMultiBranches = get(db, ['Preferences', 'ManageMultiBranches'], false);
    return manageMultiBranches ? t(updateTo) : t(current);
};


/**
* iterates over the code points of an input string and returns true if an emoji is found.
* 
* an emoji is found if the hex code for the character is 5 characters starting with "1F",
* or if @includeBasic is true, the character is 4 and starts with one of the prefixes of
* a basic emoji as defined in the Unicode specification version 13 
* see https://unicode.org/Public/emoji/13.0/emoji-sequences.txt
* 
* @input the string to check
* @includeBasic include also the basic emojis that only take 3 characters
*/
export function containsEmojis(input, includeBasic) {

    if (typeof includeBasic == "undefined")
        includeBasic = true;

    for (var c of input) {
        if (c === '–') continue;
        var cHex = ("" + c).codePointAt(0).toString(16);
        var lHex = cHex.length;
        if (lHex > 3) {

            var prefix = cHex.substring(0, 2);

            if (lHex == 5 && prefix == "1f") {
                return {contains: true, caughtChar: '–'};
            }

            if (includeBasic && lHex == 4) {
                if (["20", "21", "23", "24", "25", "26", "27", "2B", "29", "30", "32"].indexOf(prefix) > -1){
                    return {contains: true, caughtChar: '–'};
                }
                    
            }
        }
    }

    return {contains: false, caughtChar: undefined};
}

export function truncate(str, n){
    return (str.length > n) ? str.slice(0, n-1) + '..' : str;
  };

export const isObject = (item) => {
return (item && typeof item === 'object' && !Array.isArray(item));
}
export const mergeDeep = (target, ...sources) => {
    if (!sources.length) return target;
    const source = sources.shift();
    if (isObject(target) && isObject(source)) {
        for (const key in source) {
        if (isObject(source[key])) {
            if (!target[key]) Object.assign(target, {
            [key]: {}
            });
            mergeDeep(target[key], source[key]);
        } else {
            Object.assign(target, {
            [key]: source[key]
            });
        }
        }
    }
    return mergeDeep(target, ...sources);
}

export const returnCurrencyPrefix = ({db, useNonSpecialSymbol}) => {
    if (!db){
        return ''
    }
    let storedCurrencyType = get(db, ['Preferences', "currency"], useNonSpecialSymbol ? 'TK': '৳');
    return get(currency_code, [storedCurrencyType, useNonSpecialSymbol ? 'symbol' : 'symbol_native'], useNonSpecialSymbol ? 'TK' :'৳') 
}

//Code to convert Currency
export function returnIntegerToCurrencyString({integer, db}){

	if (isNaN(integer) || !isFinite(integer)){
		return 'Unknown'
	}

    let currencyPrefix = returnCurrencyPrefix({db});


	let strToArray;

	if (currencyPrefix === '৳' || currencyPrefix === 'টকা'){

			//first separate the decimal digits
			let decimalDigits = String(integer).split('.');

			//first split the strings into an array of single digits each
			let intToString = String(decimalDigits[0]);
			let strLength = intToString.length;
			strToArray = intToString.split('');

			//now only add commas if there are more than 3 digits
			if (strLength>=4){

					let firstCommaIndex = strLength - 3;
					strToArray.splice(firstCommaIndex, 0, ",");

					for (let i = firstCommaIndex - 2; i>0; i-=2) {
							strToArray.splice(i, 0, ",");
					}

			}   
	}
		
    else {

		//first separate the decimal digits
		let decimalDigits = String(integer).split('.');

        let intToString = String(decimalDigits[0]);
        let strLength = intToString.length;
        strToArray = intToString.split('');

        if (strLength>=4){
            let firstCommaIndex = strLength-3;

            for (let i=firstCommaIndex; i>0; i-=3){
                strToArray.splice(i, 0, ",");
            }

				}
	}

	let finalConvertedString = strToArray.join("");

	if (finalConvertedString === 'NaN'){
		//console.log(finalConvertedString);
		return 'No Data';
	}
	
	let decimalFinalString = finalConvertedString;
		
	return `${currencyPrefix} ${decimalFinalString}`;
}


export function returnMappedProfessionRoute_Route(proff){

    if (proff === 'Teacher') {
        return 'teacher'
    }

    if (proff === 'Admin'){
        return 'admin'
    }

    if (proff === 'TeacherAssistant'){
        return 'teacherAssistant'
    }

    if (proff === 'Student'){
        return 'student'
    }

    if (proff === 'Parent'){
        return 'parent'
    }

    return '/common/pageNotFound'
}

export function splitStringIntoArrayBetweenTwoChars(str, char1, char2) {
    return str.substring(
      str.indexOf(char1) + 1,
      str.lastIndexOf(char2)
    );
}

export function getMonthName(date) {
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  return months[date.getMonth()];
}

export function convertTime12to24(time12h) {
    if (isNumber(time12h)){
        time12h = formatAMPM(new Date(parseInt(time12h)));
    }

    const [time, modifier] = time12h.split(' ');
  
    let [hours, minutes] = time.split(':');
  
    if (hours === '12') {
      hours = '00';
    }
  
    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }
  
    return `${hours}:${minutes}`;
}

export function formatAMPM(date) {
  if (typeof date === "string") {
    let [hours, minutes] = date.split(":");
    let ampm = "AM";

    if (Number(hours) > 12) {
      hours = Number(hours) - 12;
      ampm = "PM";
    }

    return `${hours}:${minutes} ${ampm}`;
  } else if (date instanceof Date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    let strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  return date;
}

export function hexToRgbA(hex, alpha) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + alpha + ')';
    }
}

export function rerouteDefaultImage(inputURL){
    if (inputURL){
        if (decodeString(inputURL) === 'https://i.imgur.com/XqL3xLp.png'){
            return `/assets/images/avatar-outline/avatar${(2)}.jpeg`;
        }
        else{
            if (decodeString(inputURL) === 'https://f002.backblazeb2.com/file/Edutech-Assets/DefaultAvatar.png'){
                return `/assets/images/avatar-outline/avatar${(2)}.jpeg`;
            }
            else{
                return decodeString(inputURL)
            }
        }
    }
    else{
        return `/assets/images/avatar-outline/avatar${2}.jpeg`;
    }
}


export function alphabetiseArrayOfJSON(arr, keyName){
    //sort this array alphabetically
    return arr?.sort((a, b) => a[keyName]?.localeCompare(b[keyName]));
}





export function getAttributes(selector) {
	return document.querySelector(selector).dataset;
}

//inputString = The string to encode
//chunks = integer which will split the string into number of chunks to be encoded sequentially 
export function encodeString(inputString, chunks, callback) {

    if (!containsSpecialCharacters(inputString)){

        return inputString
    }
	
	if (!inputString){
		return ''
	}

	let outputArr = [];

	let encLoadPercent = 0;

	if (!chunks){
		chunks = inputString.length;
	}

	let chunkedStringArr = chunkSubstr(inputString, chunks);

	let loadIncrementBy = parseInt(100/chunkedStringArr.length);

	for (let s of chunkedStringArr){

		// Not Escaped:
		// A-Z a-z 0-9 - _ . ! ~ * ' ( )
		// so manually convert them into unicodes
		let encodedChunk = encodeURIComponent(s)
		.replace(/!/g, "%21")
		.replace(/'/g, "%27")
		.replace(/\(/g, "%28")
		.replace(/\)/g, "%29")
		.replace(/\*/g, "%2A")
		.replace(/\./g, "%2E")
        .replace(/~/g, "%7E")
        .replace(/\%/g, "%");
		
		// let encodedChunk = encodeURIComponent(s);

		outputArr.push(encodedChunk);
		encLoadPercent = encLoadPercent + loadIncrementBy;	//this will increment between 0 to hundred with number of chunk incremenets
		if (callback) callback(encLoadPercent);
	}

	let outputstring = outputArr.join('');

	return outputstring;
}

export function decodeString(inputString, chunks, callback) {

	if (!inputString){
		return ''
	}

	let outputArr = [];

	let encLoadPercent = 0;

	if (!chunks){
		chunks = inputString.length;
	}

	let chunkedStringArr = chunkSubstr(inputString, chunks);

	let loadIncrementBy = parseInt(100/chunkedStringArr.length);

	for (let s of chunkedStringArr){
        let decodedChunk;
        try{
            decodedChunk = decodeURIComponent(s);
        }
        catch(e){
            console.log(`Failed to decodeString ${s} with error ${e}`);
            decodedChunk = (s);
        }
		let withoutDots = decodedChunk.replace("%2E", ".");

		let withoutSpace = withoutDots.replace("%20", " ");
		
		outputArr.push(withoutSpace);
		encLoadPercent = encLoadPercent + loadIncrementBy;	//this will increment between 0 to hundred with number of chunk incremenets
		if (callback) callback(encLoadPercent);
	}

	let outputstring = outputArr.join('');

  return outputstring;
}

export function containsSpecialCharacters(inputS){
    if(/^[a-zA-Z0-9-%]*$/.test(inputS) === false){
        return true
    }
    else{
        return false
    }
}

export function chunkSubstr(str, size) {

	let numChunks = Math.ceil(str.length / size);

	if (!numChunks){
		numChunks = str.length;
	}

	const chunks = new Array(numChunks);
  
	for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
	  chunks[i] = str.substr(o, size)
	}
  
	return chunks
  }

export function resizeImage(MAX_WIDTH, MAX_HEIGHT, fileObj) {
  let p = new Promise((resolve, reject) => {
		let file = fileObj;

		if (file === undefined){
			//resolve and return a default file image
			file = 'https://i.imgur.com/lF5OiR1.jpg';
			resolve({ fileName: 'Placeholder', data: file });
		}

		// Create a file reader
		let reader = new FileReader();
		// Set the image once loaded into file reader
		reader.onload = function(e) {
		let img = new Image();

		img.onload = function() {
			let canvas = document.createElement("canvas");
			let ctx = canvas.getContext("2d");
			ctx.drawImage(img, 0, 0);

			let width = MAX_WIDTH;
			let height = MAX_HEIGHT;
			canvas.width = width;
			canvas.height = height;
			ctx = canvas.getContext("2d");
			ctx.drawImage(img, 0, 0, width, height);

			let dataurl = canvas.toDataURL("image/png");
			resolve({ fileName: file.name, data: dataurl });
		};

		img.src = e.target.result;
		};

		// Load files into file reader
		reader.readAsDataURL(file);
  });

  return p;
}

export function isError(obj){
    return Object.prototype.toString.call(obj) === "[object Error]";
}

 // convert A to 1, Z to 26, AA to 27
export function lettersToNumber(letters){
    if (!letters) return letters
    for(var p = 0, n = 0; p < letters.length; p++){
        n = letters[p].charCodeAt() - 64 + n * 26;
    }
    return n;
}

export function numberToLetters(num) {
    let letters = ''
    while (num >= 0) {
        letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[num % 26] + letters
        num = Math.floor(num / 26) - 1
    }
    return letters
}

export function sortAlpbhabeticallyThenAscendingJSONArray(jsonArray, labelKey){
    let workingData = [...jsonArray];

    workingData?.sort( ( a, b ) =>{
            let aCheck      = (a[labelKey])?.toLowerCase();
            let bCheck      = (b[labelKey])?.toLowerCase();
            return aCheck > bCheck ? 1 : bCheck > aCheck ? -1 : 0
        }
    );

    workingData.sort((a, b) => {
        let aID = get(a, [labelKey], '000');
        let bID = get(b, [labelKey], '000');

        const numbericA = parseInt(aID.replace(/\D/g,'')); // remove all non-number from the string and cast to integer
        const numbericB = parseInt(bID.replace(/\D/g,''));

        return numbericA - numbericB
    });

    return workingData
}

//will return as integer total characters used - english char count as one and bangla char count as two
export function totalCharactersUSED(fullText){
    if (fullText === undefined) return 0;

    fullText            = String(fullText);
    let bangla          = /[\u0980-\u09FF]/; // for check bangla 
    let newArray        = fullText.split('');
    
    let doesContainBangla = false;

    let total = 0;
    for(let i in newArray){
        if (bangla.test(newArray[i])){
            total = total + 1;
            doesContainBangla = true;
        }
        else{
            total = total + 1;
        }   
    }

    if (doesContainBangla === true){
        return total*2
    }

    return total;
}

export function NumbersToLetters(n) {
    var ordA = 'a'.charCodeAt(0);
    var ordZ = 'z'.charCodeAt(0);
    var len = ordZ - ordA + 1;
  
    var s = "";
    while(n >= 0) {
        s = String.fromCharCode(n % len + ordA) + s;
        n = Math.floor(n / len) - 1;
    }
    return s.toUpperCase();
}

export function excelQueryLanguage({fullString, excelFileData, allPhone}){

    let txt                     = fullString;
    
    const regExp                = /\{([^{]+)\}/g;
    const matchedArr            = txt.match(regExp);
    let matches                 = [];
    if (matchedArr){
        matches                 = [...txt.match(regExp)];
    }
    
    let output                  = [];
    let isDynamicRowsIncrement  = false;

    let phoneIndex          = 0;
    for (let onePhone of allPhone){

        let inputStr_Copy           = fullString;
        let doISkipThisRow          = false;

        for (let oneMatch of matches){

            let curlyBracketRemoved     = oneMatch.slice(1, -1);
            let fileKeyID               = curlyBracketRemoved.split('(')[0];
            let excelRows               = get(excelFileData, [fileKeyID, 'file'], []);
            let FULL_excelCellName      = (curlyBracketRemoved.split('(')[1])?.slice(0,-1);
            let excelCellNameDUP        = FULL_excelCellName;
            let excelCellNameDUP2       = FULL_excelCellName;

            let cellRowNumber           = undefined;
            let cellAlphabetCol         = undefined;

            if (FULL_excelCellName.includes('++')){

                //console.log('DETECTED ++ IN MATCHED TEXT!', FULL_excelCellName);

                //remove the ++ and add phone index place to the row number

                if (!oneMatch.includes('INCLUDE_IF')){
                    excelCellNameDUP = excelCellNameDUP.slice(0,-2);
                    excelCellNameDUP = excelCellNameDUP?.replace(/\D/g, "");
    
                    //console.log('excelCellNameDUP: ', excelCellNameDUP);
    
                    cellRowNumber           = parseInt(excelCellNameDUP) + phoneIndex;
                    cellAlphabetCol         = excelCellNameDUP2?.replace(/[0-9]/g, ''); 
    
                    cellAlphabetCol         = cellAlphabetCol.slice(0, -2);
    
                    // console.log('ROW: ', cellRowNumber);
                    // console.log('COL: ', cellAlphabetCol);
                }
                else{
                    //yes a skip argument is added here

                    excelCellNameDUP = excelCellNameDUP.slice(0,-2);
                    excelCellNameDUP = excelCellNameDUP?.replace(/\D/g, "");
    
                    //console.log('excelCellNameDUP: ', excelCellNameDUP);
    
                    cellRowNumber               = parseInt(excelCellNameDUP) + phoneIndex;
                    cellAlphabetCol             = excelCellNameDUP2?.replace(/[0-9]/g, ''); 
    
                    cellAlphabetCol             = cellAlphabetCol.slice(0, -2);

                    let toCheckValues           = undefined;

                    let cellColumnNumber_        = parseInt(lettersToNumber(cellAlphabetCol));    

                    if (isNumber(cellRowNumber) && isNumber(cellColumnNumber_)){
                        if (excelRows[cellRowNumber-1]){
                            toCheckValues    = excelRows[cellRowNumber-1][cellColumnNumber_-1];
                        }
                    }

                    let splitSKIP_IF            =  oneMatch.split('INCLUDE_IF');
                    let skipArgument            =  (splitSKIP_IF[1]).slice(0,-2);

                    if (skipArgument.includes('==')){
                        let compareValue = skipArgument.split('==')[1];

                        if (isNumber(compareValue) && isNumber(toCheckValues)){
                            //compare as numbers
                            if (parseFloat(toCheckValues) !== parseFloat(compareValue)){
                                doISkipThisRow = true;
                            }
                        }
                        else{
                            //compare as strings
                            if (String(toCheckValues) !== String(compareValue)){
                                doISkipThisRow = true;
                            }
                        }

                    }
                    else if (skipArgument.includes('>')){
                        let compareValue = skipArgument.split('>')[1];

                        if (isNumber(compareValue) && isNumber(toCheckValues)){
                            //compare as numbers
                            if (parseFloat(toCheckValues) < parseFloat(compareValue)){
                                doISkipThisRow = true;
                            }
                        }


                    }
                    else if (skipArgument.includes('<')){
                        let compareValue = skipArgument.split('<')[1];

                        if (isNumber(compareValue) && isNumber(toCheckValues)){
                            //compare as numbers
                            if (parseFloat(toCheckValues) > parseFloat(compareValue)){
                                doISkipThisRow = true;
                            }
                        }

                    }

                }
                isDynamicRowsIncrement = true;
            }
            else{
                cellRowNumber           = parseInt(excelCellNameDUP?.replace(/\D/g, ""));
                cellAlphabetCol         = excelCellNameDUP2?.replace(cellRowNumber, ''); 
            }

            let cellColumnNumber        = parseInt(lettersToNumber(cellAlphabetCol));    
            let textReplaceFromExcel    = undefined;
    
            if (isNumber(cellRowNumber) && isNumber(cellColumnNumber)){
                if (excelRows[cellRowNumber-1]){
                    textReplaceFromExcel    = excelRows[cellRowNumber-1][cellColumnNumber-1];
                }
            }
    
            // console.log({
            //     FILE_ID             : fileKeyID, 
            //     CELL_NAME_FULL      : FULL_excelCellName, 
            //     COL_ALPHABET        : cellAlphabetCol,
            //     ROW_NUMBER          : cellRowNumber-1,
            //     COLUMN_NUMBER       : cellColumnNumber-1,
            //     TEXT_REPLACE        : textReplaceFromExcel,
            //     excelRows           
            // });


            
            if (textReplaceFromExcel){
                inputStr_Copy = inputStr_Copy.replace(oneMatch, textReplaceFromExcel);
            }
    
        }

        onePhone = fixBangladeshPhoneNumbers(onePhone);

        phoneIndex++;

        if (onePhone === null || onePhone === undefined){
            doISkipThisRow = true;
        }

        if (doISkipThisRow === false){
            output.push({
                text        : inputStr_Copy,
                phone       : onePhone
            })
        }

    }



    return [output, isDynamicRowsIncrement]
}

// Function to check letters and numbers
export function alphanumeric(inputtxt) {
    var letterNumber = /^[\w\-\s]+$/;

    if((inputtxt.match(letterNumber))) {
        return true;
    }
    else    { 
        return false; 
    }
}
  

export function ConvertHourTimeToMinutes(time_){
  //input time as 6:00 or 13:30

  let hourMinute = parseInt(time_.split(':')[0])*60;
  let minuteMinute = parseInt(time_.split(':')[1]);

  let totalMinutes = hourMinute + minuteMinute;

  return totalMinutes
}

export function convertMS( milliseconds ) {
	let day, hour, minute, seconds;
	seconds = Math.floor(milliseconds / 1000);
	minute = Math.floor(seconds / 60);
	seconds = seconds % 60;
	hour = Math.floor(minute / 60);
	minute = minute % 60;
	day = Math.floor(hour / 24);
	hour = hour % 24;
	return {
			day: day,
			hour: hour,
			minute: minute,
			seconds: seconds
	};
}

export function ConvertMinutesToHour(totalMinutes){
  //input as 400 or 900 etc of minutes
  let Hour_ = String(Math.floor( totalMinutes / 60));
  let minutes = String(totalMinutes % 60);

  if (minutes === 0) {
      return (Hour_ + ':' + minutes + '0');
  } else {
      return (Hour_ + ':' + minutes);
  }

}

export function isValidPhoneNumber(number){

    if (!number) return false

    if (number !== "" && number.length === 14 && number.startsWith("+880") && isNumber(number)) {
        return true;
    } 
    else if (number === "") {
        return false;
    }
    else{
        return false;
    }
};


//will take a string of numbers with comma and return them as arrays
export function returnCommaSeparatedNumbers_AsArr(numbers){

    let numbersArray    = [];
    let split           = numbers.split(",");
    
    for (let number of split){

        if (!number) continue;

        let toCheckNumber = number.trim();

        toCheckNumber = fixBangladeshPhoneNumbers(toCheckNumber);

        if(isValidPhoneNumber(toCheckNumber.trim())){
            numbersArray.push(toCheckNumber.trim());
        }
    }

    return numbersArray;
}

//will take a string of numbers with spaces and return them as arrays
export function returnSpaceSeparatedNumbers_AsArr(numbers){
    let numbersArray    = [];
    let split           = numbers.split(" ");
    
    for (let number of split){
        if (!number) continue;

        let toCheckNumber = number.trim();

        toCheckNumber = fixBangladeshPhoneNumbers(toCheckNumber);

        if(isValidPhoneNumber(toCheckNumber.trim())){
            numbersArray.push(toCheckNumber.trim());
        }
    }
    return numbersArray;
}


export function checkURL_endsAsImage(url) {
    return(url.match(/\.(jpeg|jpg|png)$/) == null);
}



export function tConvert (time) {

	let intTime = parseInt(String(time).split(':').join(''));

	if (intTime > 600 && intTime < 945){

		return `${time} AM`
	}

	if (intTime === 2400){
		return `12:00 AM`
	}

	if (intTime === 2430){
		return `12:30 AM`
	}

  // Check correct time format and split into components
  time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  if (time.length > 1) { // If time format correct
    time = time.slice (1);  // Remove full string match value
    time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours

  }

  return time.join (''); // return adjusted time or original string
}

export function CraftTimingArray(startingIndex) {
	//this function will return an array containing the timings from startingIndex number to 12PM in 30min intervals
	//in 24hour format
	let Crafted_Timings = [];

	for (let i = startingIndex; i < 25; i++) {
		let currentTiming = String(i) + ':00';

		Crafted_Timings.push(currentTiming);

		let currentTiming2 = String(i) + ':30';

		Crafted_Timings.push(currentTiming2);
	}

	Crafted_Timings.pop();
	return Crafted_Timings
}


export function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
}

export function convertArrayToJSON(inputArr){
    let outputJSON = {};
    for (let oneOption of inputArr){
        let uniqueKey = `${(new Date()).getTime()}-${generateKey(5)}`
        outputJSON[uniqueKey] = oneOption;
    }
    return outputJSON
}

export function convertJSONToArr(inputJSON){
    let output = [];
    for (let oneOption in inputJSON){
        output.push(inputJSON[oneOption]);
    }
    return output
}


export function isOnlyWhitespace(inputString){
	//returns true if supplied string is only whitespace

	let sArr = inputString.split(' ');

	let output = true;

	for (let el of sArr){
		if (el!==''){
			output=false;
		}
	}

	return output;
}

export function isNumber(n) {
	return !isNaN(parseFloat(n)) && isFinite(n);
}

export function isPositive(n) {
	let output = true;
	if (n<0){
		output = false;
	}
	return output;
}

export function openInNewTab(url) {
	let win = window.open(url, '_blank');
	win.focus();
}



export function minTwoDigits(n) {
	return (n < 10 ? '0' : '') + n;
}


export function generalValidation(inputJSON){
	//accepts a JSON of values and then outputs warnings if any of them are not validated
	//returns true if any input value is NOT validated and returns false if all input vals are validated
	//example input inputJSON = {
	// 	textFields: ['a', 'b', 'c'],
	// 	numberFields: ['12', '-45', '56'],
	//	imageFields: ['url', 'fileURL']
	// }

	let inputTextArr = [];
	let inputNumberArr = [];
	let inputImageArr = [];

	if (inputJSON['textFields']){
		inputTextArr = inputJSON['textFields']
	}

	if (inputJSON['numberFields']){
		inputNumberArr = inputJSON['numberFields']
	}

	if (inputJSON['imageFields']){
		inputImageArr = inputJSON['imageFields']
	}

	for (let input of inputTextArr){
		console.log('CHECKING: ', input);
		let checkstring1 = includes(input, '<');
		let checkstring2 = includes(input, '>');
		if ((!input) || (isOnlyWhitespace(input) === true) ) {

			return true;
		} else if ((checkstring1 === true) || (checkstring2 === true)) {
			return true;
		}

	}
	
	for (let input of inputNumberArr){

		if ((!input) || (isOnlyWhitespace(input) === true) || (!isNumber(input)) || (!isPositive(input)) ) {

			return true;
		}
	}

	for (let input of inputImageArr){

		imageExists(input, function(exists) {	
			if (exists===false){
				return true
			}
		});
	}

	return false
}

export function generalValidationWithScript(inputJSON) {
	let inputTextArr = [];

	if (inputJSON['textFields']){
		inputTextArr = inputJSON['textFields']
	}

	for (let input of inputTextArr) {

		console.log('input value: ', input);

		let checkstring1 = includes(input, '<script>');
		let checkstring2 = includes(input, '</script>');

		if ((!input) || (isOnlyWhitespace(input) === true) ) {

			return true;
		} else if ((checkstring1 === true) || (checkstring2 === true)) {

			return true;
		}

	}

	return false;
}

export function characterLimitValidation(inputArr, chrLimit){

    let output;
    for (let input_ of inputArr){
        if (input_.length > chrLimit){
            output = false;
        }
        else{
            output = true;
        }
    }

    return output;
}

//Code to convert Currency
export function convertToCurrency(integer, db, useNonSpecialSymbol){

    let symbolToUse = returnCurrencyPrefix({db, useNonSpecialSymbol});
    let currency    = 'Bangladesh';

    if (symbolToUse !== '৳' && symbolToUse !== 'BDT' && symbolToUse !== 'TK'){
        currency = 'Foreign';
    }

	if (isNaN(integer) || !isFinite(integer)){
		return 'Unknown'
	}

	let strToArray;

	if (currency === 'Bangladesh'){

			//first separate the decimal digits
			let decimalDigits = String(integer).split('.');

			//first split the strings into an array of single digits each
			let intToString = String(decimalDigits[0]);
			let strLength = intToString.length;
			strToArray = intToString.split('');

			//now only add commas if there are more than 3 digits
			if (strLength>=4){

					let firstCommaIndex = strLength - 3;
					strToArray.splice(firstCommaIndex, 0, ",");

					for (let i = firstCommaIndex - 2; i>0; i-=2) {
							strToArray.splice(i, 0, ",");
					}

			}   
	}
		
    else if (currency==='Foreign'){

		//first separate the decimal digits
		let decimalDigits = String(integer).split('.');

        let intToString = String(decimalDigits[0]);
        let strLength = intToString.length;
        strToArray = intToString.split('');

        if (strLength>=4){
            let firstCommaIndex = strLength-3;

            for (let i=firstCommaIndex; i>0; i-=3){
                strToArray.splice(i, 0, ",");
            }

				}
	}

	let finalConvertedString = strToArray.join("");

	if (finalConvertedString === 'NaN'){
		return 'No Data';
	}
	
	let decimalFinalString = finalConvertedString;
		
	return `${symbolToUse} ${decimalFinalString}`;
}

export function reverseChildNodes(node) {
	
	let parentNode = node.parentNode
	let nextSibling = node.nextSibling
	let frag = node.ownerDocument.createDocumentFragment();

	parentNode.removeChild(node);
	while(node.lastChild)
			frag.appendChild(node.lastChild);
	node.appendChild(frag);
	parentNode.insertBefore(node, nextSibling);
	return node;
}

export function filterArrUndefined(arr){
	//this will remove and return the arr with all undefined values removed

	let newArr = [];

	for (let x of arr){

		if (x){
			newArr.push(x);
		}
	}

	return newArr
}



// check if image url exists or not
// The "callback" argument is called with either true or false
// depending on whether the image at "url" exists or not.
export function imageExists(url, callback) {
    var img = new Image();
    img.onload = function() { callback(true);};
    img.onerror = function() {callback(false);};
    img.src = url;
}	

        
export function toDataURL(src, callback, outputFormat) {
    var img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = function () {
        var canvas = document.createElement('CANVAS');
        var ctx = canvas.getContext('2d');
        var dataURL;
        canvas.height = this.naturalHeight;
        canvas.width = this.naturalWidth;
        ctx.drawImage(this, 0, 0);
        dataURL = canvas.toDataURL(outputFormat);
        callback(dataURL);
    };
    img.src = src;
    if (img.complete || img.complete === undefined) {
        img.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
        img.src = src;
    }
}


export function toDataURL_promisified(src, outputFormat) {
    return new Promise((resolve, reject) => {
        var img = new Image();
        img.crossOrigin = 'Anonymous';
        img.onload = function () {
            var canvas = document.createElement('CANVAS');
            var ctx = canvas.getContext('2d');
            var dataURL;
            canvas.height = this.naturalHeight;
            canvas.width = this.naturalWidth;
            ctx.drawImage(this, 0, 0);
            dataURL = canvas.toDataURL(outputFormat);
            resolve(dataURL);
        };
        img.onerror = function (error) {
            reject(error);
        };
        img.src = src;
        if (img.complete || img.complete === undefined) {
            img.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
            img.src = src;
        }
    });
}

  

export function getBase64(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
	});
}


export function getEMValue() {
    let em;
    let div = document.getElementById('divEM');
    div.style.height = '1em';
    em = div.offsetHeight;

    return em;
}

// Working with dates and time
// Source: https://stackoverflow.com/questions/3552461/how-to-format-a-javascript-date/34015511#34015511
export function formatDate(date) {
	const h = '0' + new Date(date).getHours();
	const m = '0' + new Date(date).getMinutes();
	//const s = '0' + new Date(date).getSeconds(); // seconds
	//const ms = new Date(date).getMilliseconds(); // milliseconds

	let options = { year: '2-digit', month: 'short', day: 'numeric' };

	return `${new Date(date).toLocaleDateString("en-US", options)} | ${h.slice(-2)}:${m.slice(-2)}`;
}


export function formatDateAMPM(date){
	var todayDate = new Date(date);
	var getTodayDate = todayDate.getDate();
	var getTodayMonth =  todayDate.getMonth()+1;
	var getTodayFullYear = todayDate.getFullYear();
	var getCurrentHours = todayDate.getHours();
	var getCurrentMinutes = todayDate.getMinutes();
	var getCurrentAmPm = getCurrentHours >= 12 ? 'PM' : 'AM';
	getCurrentHours = getCurrentHours % 12;
	getCurrentHours = getCurrentHours ? getCurrentHours : 12; 
	getCurrentMinutes = getCurrentMinutes < 10 ? '0'+getCurrentMinutes : getCurrentMinutes;
	var getCurrentDateTime = getTodayDate + '-' + getTodayMonth + '-' + getTodayFullYear + ' ' + getCurrentHours + ':' + getCurrentMinutes + ' ' + getCurrentAmPm;

	return getCurrentDateTime
}

// calculate time difference
export function timeDifference(start, end) {
    start = start.split(":");
    end = end.split(":");
    var startDate = new Date(0, 0, 0, start[0], start[1], 0);
	var endDate = new Date(0, 0, 0, end[0], end[1], 0);
    var diff = endDate.getTime() - startDate.getTime();
    var hours = Math.floor(diff / 1000 / 60 / 60);
    diff -= hours * 1000 * 60 * 60;
    var minutes = Math.floor(diff / 1000 / 60);

    // If using time pickers with 24 hours format, add the below line get exact hours
    if (hours < 0)
       hours = hours + 24;

    return (hours <= 9 ? "0" : "") + hours + ":" + (minutes <= 9 ? "0" : "") + minutes;
}

export function cartesian() {
    var r = [], arg = arguments, max = arg.length - 1;
    function helper(arr, i) {
        for (var j = 0, l = arg[i].length; j < l; j++) {
            var a = arr.slice(0); // clone arr
            a.push(arg[i][j]);
            if (i === max)
                r.push(a);
            else
                helper(a, i + 1);
        }
    }
    helper([], 0);
    return r;
}

export function returnDayAsOptions(){

	return /*html*/`
		<option vale="Sunday">Sunday</option>
		<option vale="Monday">Monday</option>
		<option vale="Tuesday">Tuesday</option>
		<option vale="Wednesday">Wednesday</option>
		<option vale="Thursday">Thursday</option>
		<option vale="Friday">Friday</option>
		<option vale="Saturday">Saturday</option>
	`
}

export function returnTimingsAsOptions(){

	return /*html*/`
		<option value="7:30">7:30 AM</option>
		<option value="8:00">8:00 AM</option>
		<option value="8:30">8:30 AM</option>
		<option value="9:00">9:00 AM</option>
		<option value="9:30">9:30 AM</option>
		<option value="10:00">10:00 AM</option>
		<option value="10:30">10:30 AM</option>
		<option value="11:00">11:00 AM</option>
		<option value="11:30">11:30 AM</option>
		<option value="12:00">12:00 PM</option>
		<option value="12:30">12:30 PM</option>
		<option value="13:00">1:00 PM</option>
		<option value="13:30">1:30 PM</option>
		<option value="14:00">2:00 PM</option>
		<option value="14:30">2:30 PM</option>
		<option value="15:00">3:00 PM</option>
		<option value="15:30">3:30 PM</option>
		<option value="16:00">4:00 PM</option>
		<option value="16:30">4:30 PM</option>
		<option value="17:00">5:00 PM</option>
		<option value="17:30">5:30 PM</option>
		<option value="18:00">6:00 PM</option>
		<option value="18:30">6:30 PM</option>
		<option value="19:00">7:00 PM</option>
		<option value="19:30">7:30 PM</option>
		<option value="20:00">8:00 PM</option>
		<option value="20:30">8:30 PM</option>
		<option value="21:00">9:00 PM</option>
		<option value="21:30">9:30 PM</option>
		<option value="22:00">10:00 PM</option>
		<option value="22:30">10:30 PM</option>
		<option value="23:00">11:00 PM</option>
		<option value="23:30">11:30 PM</option>
	`
}

export function preload(images) {
    for (var i = 0; i < images.length; ++i) {
        var img = new Image();
        img.src = images[i];
    }
}

export function isIncognito(callbackTrue, callbackFalse){
    let fs = window.RequestFileSystem || window.webkitRequestFileSystem;
    if (!fs) {
      callbackFalse();
    } else {
      fs(window.TEMPORARY,
         100,
         ()=>{
             console.log('Setting browser to non incognito');
            callbackFalse();
         },
         ()=>{
             console.log('Setting browser to incognito');
             callbackTrue();
         });
    }
}

export function arrayHasUndefined(arr){
	for (let x of arr){

		if (x === undefined){
			return true
		}
	}

	return false
}

export function arraySum(arr){
	let output = 0;
	for (let x of arr){
		if (x !== undefined){
			output = output + x;
		}
	}

	return output
}

//will return random number between two numbers with them included
export function pickRandom(first, second){
	return Math.floor(Math.random() * first + second);
}

export function fullTime(){
	let today = new Date();
	let options = {
		weekday: "long", year: "numeric", month: "short",
		day: "numeric", hour: "2-digit", minute: "2-digit"
	};

	let formattedTime = today.toLocaleString("en-us", options); 

	return formattedTime;
}

export function fullTimeCustom(dateInMiliseconds){
	let givenDate = new Date(dateInMiliseconds);
	let options = {
		weekday: "long", year: "numeric", month: "short",
		day: "numeric", hour: "2-digit", minute: "2-digit"
	};

	let formattedTime = givenDate.toLocaleString("en-us", options); 

	return formattedTime;
}

export function doIHaveThisAddOnAvailable(db, addOnKey){

    let firebaseKey = addOnKey;
    if (db){
        if (get(db, ['PublicInfo', 'UserProffession'], undefined) === 'TeacherAssistant'){


            let installedToolsArr   =   get(db, ['VolatileLocalData', 'InstalledTools'], []);
            let installedToolsKeys  =   get(db, ['VolatileLocalData', 'InstalledToolsKeys'], []);

            if (!Array.isArray(installedToolsArr)){
                installedToolsArr = Object.values(installedToolsArr);
            }

            if (!Array.isArray(installedToolsKeys)){
                installedToolsKeys = Object.values(installedToolsKeys);
            }

            for (let oneTool of installedToolsArr){

                let oneToolKey = oneTool['key'];

                if (firebaseKey !== oneToolKey) continue;

                //if this key is available in teacher db then add it to installed panel
                if (installedToolsKeys.includes(oneToolKey)){

                    let expiresOn = get(installedToolsArr[installedToolsKeys.indexOf(oneToolKey)], ['Expires'], undefined);

                    if (expiresOn !== undefined){
    
                        let timeStampNOW = (new Date()).getTime();
        
                        if (parseInt(expiresOn) < timeStampNOW){
                            return false
                        }
                        else{
                            return true
                        }
                    }
                    else{
                        return true
                    }

                }
                else{
                    return false
                }
            }

        }
        else{
            //use own installed tools of teacher / admin
            //if it is an admined teacher account then use installed tools from the admin account
            let isAdmined       = get(db, ['Admined'], undefined);
            if (isAdmined === undefined){
                if (get(db, ['InstalledTools', firebaseKey], undefined) !== undefined){

                    let expiresOn = get(db, ['InstalledTools', firebaseKey, 'Expires'], undefined);
    
                    if (expiresOn !== undefined){
    
                        let timeStampNOW = (new Date()).getTime();
        
                        if (parseInt(expiresOn) < timeStampNOW){
                            return false
                        }
                        else{
                            return true
                        }
                    }
                    else{
                        return true
                    }
                }
            }
            else{

                database.ref(`USERS/${isAdmined}/InstalledTools/`).once('value')
                .then(snapshot=>{

                    let installedTOOLS_JSON = snapshot.exists() ? snapshot.val() : {};


                    if (get(installedTOOLS_JSON, [firebaseKey], undefined) !== undefined){

                        let expiresOn = get(installedTOOLS_JSON, [ firebaseKey, 'Expires'], undefined);
        
                        if (expiresOn !== undefined){
        
                            let timeStampNOW = (new Date()).getTime();
            
                            if (parseInt(expiresOn) < timeStampNOW){
                                return false
                            }
                            else{
                                return true
                            }
                        }
                        else{
                            return true
                        }
                    }


                })
            }
        }
        return true ;// force return True, now this function will always return true
    }

}




export function does_object_have_child_keyName(jsonObj, keyName){

	for (let key in jsonObj){
		if (key === keyName){
			return true
		}
	}

	return false;
}




export function httpPostRequest(url, body, errorMsg, callback) {
	var http = new XMLHttpRequest();
	http.open('POST', url, true);
	http.setRequestHeader('Content-type', 'application/json');
	http.addEventListener('readystatechange', processRequest, false);
	http.send(JSON.stringify(body));

	function processRequest() {
		if (http.readyState === 4) {
			if (http.status === 200) {
				try {
					callback(JSON.parse(http.responseText));
				} catch (e) {
					callback();
				}
			} else {
				console.warn(errorMsg);
				console.warn(http.responseText);
			}
		}
	}
}


export function removeEmptyStringFromArr(arr){
	let arr_ = [];
	for (let x of arr){
		if (x!==''){
			arr_.push(x);
		}
	}
	return arr_
}

export function memorySizeOf(obj) {
    var bytes = 0;

    function sizeOf(obj) {
        if (obj !== null && obj !== undefined) {
            switch (typeof obj) {
                case 'number':
                    bytes += 8;
                    break;
                case 'string':
                    bytes += obj.length * 2;
                    break;
                case 'boolean':
                    bytes += 4;
                    break;
                case 'object':
                    var objClass = Object.prototype.toString.call(obj).slice(8, -1);
                    if (objClass === 'Object' || objClass === 'Array') {
                        for (var key in obj) {
                            if (!obj.hasOwnProperty(key)) continue;
                            sizeOf(obj[key]);
                        }
                    } else bytes += obj.toString().length * 2;
                    break;
            }
        }
        return bytes;
    };

    function formatByteSize(bytes) {
        if (bytes < 1024) return bytes + " bytes";
        else if (bytes < 1048576) return (bytes / 1024).toFixed(3) + " KiB";
        else if (bytes < 1073741824) return (bytes / 1048576).toFixed(3) + " MiB";
        else return (bytes / 1073741824).toFixed(3) + " GiB";
    };

    return formatByteSize(sizeOf(obj));
};

export function memorySizeOf_inBytes(obj) {
    var bytes = 0;

    function sizeOf(obj) {
        if (obj !== null && obj !== undefined) {
            switch (typeof obj) {
                case 'number':
                    bytes += 8;
                    break;
                case 'string':
                    bytes += obj.length * 2;
                    break;
                case 'boolean':
                    bytes += 4;
                    break;
                case 'object':
                    var objClass = Object.prototype.toString.call(obj).slice(8, -1);
                    if (objClass === 'Object' || objClass === 'Array') {
                        for (var key in obj) {
                            if (!obj.hasOwnProperty(key)) continue;
                            sizeOf(obj[key]);
                        }
                    } else bytes += obj.toString().length * 2;
                    break;
            }
        }
        return bytes;
    };

    return (sizeOf(obj));
};

export function uniqueJSONArray(inputJSONArray, key) {

    let alreadyAddedVal     = [];
    let newArray            = [];

    for (let obj of inputJSONArray){
        if (!alreadyAddedVal.includes(obj[key])){
            alreadyAddedVal.push(obj[key]);
            newArray.push(obj);
        }
    }

    return newArray;
};

export function removeDuplicatesInCommaString(inputString){
	if(inputString) {
		let stringArr = inputString.split(',');
	
		let unique = [...new Set(stringArr)];
	
		let newString = unique.join(', ');
	
		return newString;
	}
}

export function containsDuplicates(array) {

    if (array.length !== new Set(array).size) {
      return true;
    }
  
    return false;
}

export function getUrlVars() {
    let vars = {};

    let argPart = window.location.href.split('?');
    let decodedArgPart = decodeString(argPart[1]);

    let valArr = decodedArgPart.split('&');

    for (let x of valArr){

        let z = x.split('=');

        vars[z[0]] = z[1];
    }

    return vars;
}


export function return_timezone(dt) 
{ 
  return /\((.*)\)/.exec(new Date().toString())[1];
}

export function arrayMin(arr) {
	var len = arr.length, min = Infinity;
	while (len--) {
	  if (Number(arr[len]) < min) {
		min = Number(arr[len]);
	  }
	}
	return min;
  };
  
export function arrayMax(arr) {
	var len = arr.length, max = -Infinity;
	while (len--) {
	  if (Number(arr[len]) > max) {
			max = Number(arr[len]);
	  }
	}
	return max;
};

export function copyToClipboard(text) {
    /* Get the text field */
    var copyText = text;
  
     /* Copy the text inside the text field */
    navigator.clipboard.writeText(copyText);
  
 }

 export function decrypt(str){

	var mykey = crypto.Decipher('aes-256-cbc', 'zoom_edutech_2');
	var mystr = mykey.update(str, 'hex', 'utf8')
	mystr += mykey.final('utf8');
	return mystr;
}




//this will accept two date objects with first being the larger one and will return the day difference between them
export function dayDifference(dateObjOne, dateObjTwo){
	  
	// To calculate the time difference of two dates 
	let Difference_In_Time = dateObjOne.getTime() - dateObjTwo.getTime(); 
	  
	// To calculate the no. of days between two dates 
	let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24); 

	return Difference_In_Days;
}

export function isQuillEditorEmptyInput(inputValue) {
	const inputValueTrimed = inputValue.replace(/ /g,'');
	const arrValue = inputValueTrimed.split('');

	let isEmpty = true;

	console.log(arrValue);

	let newArr = pullAllBy(arrValue, ['<', '>', '/', 'p', 'b', 'r', '↵']);

	console.log('>>>newArr<<<', newArr);

	if(newArr.length > 0) {
		isEmpty = false;
	}

	return isEmpty;
}

export function enableProductionMode() {
	console.log = function () { };
	console.debug = function () { };
	console.info = function () { };
	console.warn = function () { };
};

export function objectNumberofChildren(obj){

	if (!obj){
		obj = {};
	}

	let num = 0;

	for (let i in obj){
		num++
	}

	return num
}








export function getMaxValueFromArrOfJsonProp(arr, prop) {
	var max = 0 ;
	for (let i in arr) {
		if (parseInt(arr[i][prop]) > max) {
			max = arr[i][prop];
		}
	}
	return max;
}


export const checkPermission = ({db, active, privilegesString, errorMessage, cb}) => {
    if(active && privilegesString){
      let isPermission = get(db, ['VolatileLocalData', 'Moderating',  active[0], 'UserClass', active[1], active[2], 'Streams', active[3], 'AcceptedModerators', db['UID'], 'permissionSpecificBatch', active[3], privilegesString], undefined) !== undefined?
      get(db, ['VolatileLocalData', 'Moderating',  active[0], 'UserClass', active[1], active[2], 'Streams', active[3], 'AcceptedModerators', db['UID'], 'permissionSpecificBatch', active[3], privilegesString], false)  
      : get(db, ['VolatileLocalData', 'Moderating',  active[0], 'UserClass', active[1], active[2], 'Streams', active[3], 'AcceptedModerators', db['UID'], 'Permission', privilegesString], false);

      if(isPermission === true){
        return cb();
      }
      else{
        return message.error(errorMessage)
      }
    }
}

export function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export function stringNotMoreThan21Charecter (string) {
    if(string?.length > 21){
        return string.substring(0,21) + "..."
    }else{
        return string
    }
  
}

export function isValidSegmentName (value) {
    const regex = /^(?![\d&\s])[a-zA-Z0-9&\s]+$/;
    if (value && regex.test(value)) {
        return false;
      } else {
        return true;
      }
}

export function isValidOnlyNumber (value) {
    const regex = /^[0-9]+$/;
    if (value && regex.test(value)) {
        return false;
      } else {
        return true;
      }
}

export function isValidRfidCode(input) {
    const numericInput = input.replace(/\D/g, ''); // Remove non-digit characters

    if (!isNaN(numericInput) && numericInput.length === 10) {
        return true;
    }

    return false;
}

export function formatDecimalNumber(number) {
    const formattedNumber = parseFloat(number).toFixed(2);
    return formattedNumber.replace(/\.?0+$/, ''); // Remove trailing zeros
}

// Function to get the current date and time from the World Time API
export async function getCurrentDateTime() {
    try {
        let response = await fetch('https://worldtimeapi.org/api/timezone/Etc/UTC');
        if (!response.ok) {
            console.log('Network response was not ok ' + response.statusText);
        }
        let data = await response.json();
        let utcDateTime = data.utc_datetime;
        return moment(utcDateTime);
    } 
    catch (error) {
        console.error('Failed to fetch current date and time so returning local normal data obj:', error);
        return moment();
    }
}

export function calculateReadingTime(description, wordsPerMin) {
    
    const wordsPerMinute = wordsPerMin;

    // Calculate number of words in description
    const words = description.split(/\s+/).length;

    // Calculate estimated reading time in minutes
    const readingTime = Math.ceil(words / wordsPerMinute);

    return readingTime;
}

export const getSlidesToShow = (width) => {
    if (width >= 1351) {
      return 3;
    } else if (width >= 950) {
      return 2;
    } else {
      return 1;
    }
};

