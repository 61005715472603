import React, { useState } from 'react'
import { Button, Collapse } from 'antd'
import { CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons'
import WelcomeHeader from '../WelcomeHeader'
import Footer from '../Footer'


const tableData = [
  { feature: 'Classrooms', lite: 'Unlimited', pro: 'Unlimited' },
  { feature: 'Students', lite: 'Unlimited', pro: 'Unlimited' },
  { feature: 'Teachers', lite: 'Unlimited', pro: 'Unlimited' },
  { feature: 'Admin account', lite: true, pro: true },
  { feature: 'Bulk Import data (from excel)', lite: true, pro: true },
  { feature: 'Bulk Import data (as pdf & excel)', lite: true, pro: true },
  { feature: 'Attendance management', lite: 'Auto & Manual', pro: 'Auto & Manual' },
  { feature: 'Auto check-in/check-out alert', lite: true, pro: true },
  { feature: 'Academic payment collection', lite: 'Cash & online', pro: 'Cash & online' },
  { feature: 'Recurring & one-time payments', lite: true, pro: true },
  { feature: 'Invoice generator & payment completion alert', lite: true, pro: true },
  { feature: 'Calendar (class timing & custom events)', lite: true, pro: true },
  { feature: 'Bulk SMS gateway', lite: true, pro: true },
  { feature: 'Dynamic SMS alerts', lite: true, pro: true },
  { feature: 'Notice Board', lite: true, pro: true },
  { feature: 'Assessments', lite: true, pro: true },
  { feature: 'Assessment segmentation & grading', lite: true, pro: true },
  { feature: 'Report card', lite: true, pro: true },
  { feature: 'Overseer - student progress tracking', lite: true, pro: true },
  { feature: 'Sentinel - student safety', lite: true, pro: true },
  { feature: 'Money manager with custom income/expense', lite: true, pro: true },
  { feature: 'Money manager activity history', lite: false, pro: true },
  { feature: 'Business insights & analytics', lite: false, pro: true },
  { feature: 'Multi-currency support', lite: false, pro: true },
  { feature: 'Stakeholder revenue/profit auto-splitting', lite: false, pro: true },
  { feature: 'Student archival', lite: false, pro: true },
  { feature: 'Edutech - auto class recording', lite: false, pro: true },
  { feature: 'Edutech AI', lite: false, pro: true },
  { feature: 'Zoom integration', lite: true, pro: true },
  { feature: 'Online admissions', lite: true, pro: true },
  { feature: 'Staff management', lite: true, pro: true },
  { feature: 'Staff attendance & salary disbursement', lite: true, pro: true },
  { feature: 'Video courses', lite: 'Up to 1TB', pro: 'Up to 10TB' },
  { feature: 'File storage', lite: 'Up to 1TB', pro: 'Up to 10TB' },
  { feature: 'Student & parent account', lite: true, pro: true },
  { feature: 'Moderators', lite: 2, pro: 'Unlimited' },
  { feature: 'Moderator privilege control', lite: 'Basic', pro: 'Full' },
  { feature: 'Data loss protection', lite: false, pro: true },
  { feature: 'Basic training for data security & cyberattacks', lite: false, pro: true },
  { feature: 'Activity tracking', lite: false, pro: true }
];

const Pricing = () => {


  const [activeKey, setActiveKey] = useState([]);

  const handleIcon = (key) => {
    return activeKey?.includes(key) ? <CloseOutlined /> : <PlusOutlined />;
  };

  const onChange = (key) => {
    setActiveKey(key);
  };


  return (
    <div className="gx-welcome-pagev2 gx-pricing-page">
      <WelcomeHeader />

      <main>
        <section className="gx-pricing-section gx-section-padding">
          <div className="gx-landing-container">
            <div className="gx-pricing-section-title gx-mb-5">
              <h1 className='gx-title1 gx-mb-2'>Pricing</h1>
              <h1 className='gx-title2'>Start for free and upgrade when you need it</h1>
            </div>
            <div className="gx-pricing-wrapper">
              <div className="gx-pricing-card gx-lite">
                <div className='gx-pricing-content'>
                  <h3>Lite</h3>
                  <p className="gx-recommendation">Recommended for most institutions</p>
                  <div className="gx-divide-line gx-my-4"></div>
                  <h2>৳0</h2>
                  <p>Per student per month</p>
                  <div className="gx-divide-line gx-my-4"></div>
                  <ul>
                    <li><CheckOutlined className="gx-pricing-check gx-mr-2" /> Unlimited students, teachers & classrooms</li>
                    <li><CheckOutlined className="gx-pricing-check gx-mr-2" /> Up to 2 moderators with limited privilege control</li>
                    <li><CheckOutlined className="gx-pricing-check gx-mr-2" /> Attendance & payment management</li>
                    <li><CheckOutlined className="gx-pricing-check gx-mr-2" /> Assessments & report cards</li>
                    <li><CheckOutlined className="gx-pricing-check gx-mr-2" /> Online admissions & payment collection</li>
                    <li><CheckOutlined className="gx-pricing-check gx-mr-2" /> Online class, video courses & file sharing</li>
                    <li><CheckOutlined className="gx-pricing-check gx-mr-2" /> Dynamic SMS alerts <span>(*sms charge applicable)</span></li>
                    <li><CheckOutlined className="gx-pricing-check gx-mr-2" /> 20+ add-ons</li>
                  </ul>

                </div>
                <Button className="gx-btn-primary gx-pricing-btn gx-mb-0 gx-mt-5 gx-rounded-xxl">Start For Free</Button>
              </div>
              <div className="gx-pricing-card gx-pro">
                <div className='gx-pricing-content gx-pro-pricing'>
                  <h3>Pro</h3>
                  <p className="gx-recommendation">For the advanced needs of large institutions</p>
                  <div className="gx-divide-line gx-my-4"></div>
                  <h2>৳20</h2>
                  <p>Per student per month</p>
                  <div className="gx-divide-line gx-my-4"></div>
                  <ul>
                    <li>Everything in Lite, plus</li>
                    <li><CheckOutlined className="gx-pricing-check gx-mr-2" /> Unlimited moderators with full privilege control</li>
                    <li><CheckOutlined className="gx-pricing-check gx-mr-2" /> 30 days data-loss protection</li>
                    <li><CheckOutlined className="gx-pricing-check gx-mr-2" /> Stakeholder revenue splitting</li>
                    <li><CheckOutlined className="gx-pricing-check gx-mr-2" /> Multi-currency support</li>
                    <li><CheckOutlined className="gx-pricing-check gx-mr-2" /> Activity tracking, business insights & analytics</li>
                    <li><CheckOutlined className="gx-pricing-check gx-mr-2" /> Basic cyber vulnerability testing</li>
                  </ul>
                </div>
                <Button className="gx-btn-outline gx-pricing-btn gx-pricing-upgrade gx-mb-0 gx-mt-5 gx-rounded-xxl">Upgrade</Button>
              </div>
            </div>
          </div>

        </section>

        <section className="gx-pricing-table-section gx-section-padding">
          <div className="gx-landing-container">
            <div className="gx-table-wrapper">
              <table className='gx-pricing-table'>
                <thead>
                  <tr>
                    <th className="gx-compare-features">Compare Features</th>
                    <th className="gx-pricing-option gx-lite-plan">
                      <h1>Lite</h1>
                      <h3>৳0/student per month</h3>
                      <Button className="gx-btn-primary gx-mb-1 gx-rounded-xxl">Start For Free</Button>
                    </th>
                    <th className="gx-pricing-option gx-pro-plan">
                      <h1>Pro</h1>
                      <h3>৳20/student per month</h3>
                      <Button className="gx-btn-outline gx-pricing-upgrade gx-mb-1 gx-rounded-xxl">Upgrade</Button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((row, index) => (
                    <tr key={index}>
                      <td>{row.feature}</td>
                      <td>{row.lite === true ? <CheckOutlined className='gx-check-outline' /> : row.lite === false ? <CloseOutlined className='gx-close-outline' /> : row.lite}</td>
                      <td>{row.pro === true ? <CheckOutlined className='gx-check-outline' /> : row.pro === false ? <CloseOutlined className='gx-close-outline' /> : row.pro}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </section>

        <section className="gx-faq-section gx-section-padding">
          <div className="gx-landing-container">
            <div className="gx-faq-wrapper">
              <h2 className='gx-mb-3'>FAQs</h2>
              <Collapse
                ghost
                expandIconPosition="end"
                bordered={false}
                activeKey={activeKey}
                onChange={onChange}
                expandIcon={({ panelKey }) => handleIcon(panelKey)}
                 className="gx-faq-custom-collapse"
              >
                <Collapse.Panel header="Can I use the free plan forever?" key="1">
                  <p>Yes. You can use the free plan forever. You should only upgrade if you require some of the advanced features that are exclusive to the paid plan.</p>
                </Collapse.Panel>
                <Collapse.Panel header="How do I upgrade to the pro plan?" key="2">
                  <p>You can upgrade just by pressing the upgrade button and logging in your account. Please note that you will be charged a subscription fee every month based on the total active students you have.</p>
                </Collapse.Panel>
                <Collapse.Panel header="How do I pay for the pro plan?" key="3">
                  <p>You can easily pay for the pro plan by making the payment through bkash, nagad, visa/mastercard and most other established payment gateways. If you are facing issues with it, contact us and we will be happy to assist you.</p>
                </Collapse.Panel>
                <Collapse.Panel header="Can I revert back to the free plan after upgrading?" key="4">
                  <p>Yes. You can revert back to the free plan whenever you want. However, please note that you will lose access to the exclusive features of the pro plan.</p>
                </Collapse.Panel>
                <Collapse.Panel header="Can I upgrade later on in the future after I start using it?" key="5">
                  <p>Yes, you can upgrade any time.</p>
                </Collapse.Panel>
              </Collapse>
            </div>
          </div>
        </section>


      </main>

      <Footer />
    </div>
  )
}

export default Pricing