import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Button, Popover } from "antd";
import { DownOutlined, LeftOutlined, CloseOutlined } from "@ant-design/icons";
import languageData from "../../Topbar/languageData";
import { switchLanguage } from "../../../appRedux/actions";
import CustomScrollbars from "../../../util/CustomScrollbars";
import DarkLightMode from "../../../components/DarkLightMode";


const WelcomeHeader = () => {
  const history                                               = useHistory();
  const dispatch                                              = useDispatch();
  const locale                                                = useSelector(({ settings }) => settings.locale);
  const themeType                                             = useSelector(({ settings }) => settings.themeType);
  const [menuActive, setMenuActive]                           = useState(false);


  const languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        {languageData.map(language => (
          <li
            className="gx-media gx-pointer"
            key={JSON.stringify(language)}
            onClick={() => dispatch(switchLanguage(language))}
          >
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
            <span className="gx-language-text">{language.name}</span>
          </li>
        ))}
      </ul>
    </CustomScrollbars>
  );

  const toggleMenu = useCallback(() => {
    setMenuActive(prev => !prev);
  }, []);

  const showSubMenu = useCallback(children => {
    const subMenu = children.querySelector(".submenu");
    subMenu.classList.add("is-active");
    subMenu.style.animation = "slideLeft 0.35s ease forwards";

    const menuTitle = children.querySelector(".gx-menu__link").childNodes[0].textContent.trim();
    document.querySelector(".gx-menu__title").textContent = menuTitle;
    document.querySelector(".menu__header").classList.add("is-active");
  }, []);

  const hideSubMenu = useCallback(() => {
    const subMenu = document.querySelector(".submenu.is-active");
    if (subMenu) {
      subMenu.style.animation = "slideRight 0.35s ease forwards";
      setTimeout(() => {
        subMenu.classList.remove("is-active");
      }, 300);
      document.querySelector(".gx-menu__title").textContent = "";
      document.querySelector(".menu__header").classList.remove("is-active");
    }
  }, []);

  const toggleSubMenu = useCallback(
    e => {
      if (!menuActive) return;
      if (e.target.closest(".menu__dropdown")) {
        const children = e.target.closest(".menu__dropdown");
        showSubMenu(children);
      }
    },
    [menuActive, showSubMenu]
  );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1200 && menuActive) {
        toggleMenu();
      }
    };

    window.addEventListener("resize", handleResize);
    document.querySelector(".gx-burger").addEventListener("click", toggleMenu);
    document.querySelector(".gx-menu-close").addEventListener("click", toggleMenu);
    document.querySelector(".gx-overlay").addEventListener("click", toggleMenu);
    document.querySelector(".gx-menu__arrow").addEventListener("click", hideSubMenu);
    document.querySelector(".gx-menu__title").addEventListener("click", hideSubMenu);
    document.querySelector(".gx-menu__inner").addEventListener("click", toggleSubMenu);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [menuActive, hideSubMenu, toggleMenu, toggleSubMenu]);

  return (
    <header className="gx-header" id="gx-header">
      <nav className="gx-navbar gx-landing-container">
        <section className="gx-navbar__left">
          <img
            style={{ cursor: "pointer" }}
            onClick={() => window.location.replace("/welcome")}
            className="sidebarFullLogo"
            alt="logo1"
            src= {`${themeType === 'THEME_TYPE_DARK' ? "/assets/images/welcomev2/logo-white.png" : "/assets/images/welcomev2/logo.png"}`}
          />
          <div className="gx-burger" id="gx-burger">
            <span className="gx-burger-line" />
            <span className="gx-burger-line" />
            <span className="gx-burger-line" />
          </div>
        </section>
        <section className="gx-navbar__center">
          <span className={`gx-overlay ${menuActive ? "is-active" : ""}`} />
          <div className={`menu ${menuActive ? "is-active" : ""}`} id="menu">
            <div className="menu__header">
              <span className="gx-menu__arrow">
                <LeftOutlined style={{ fontSize: "16px" }} />
              </span>
              <span className="gx-menu__title" />
            </div>

            <ul className="gx-menu__inner">
              <div className="gx-mobile-menu-header">
                <img
                  style={{ cursor: "pointer", width: "160px" }}
                  onClick={() => window.location.replace("/welcome")}
                  alt="logo1"
                  src= {`${themeType === 'THEME_TYPE_DARK' ? "/assets/images/welcomev2/logo-white.png" : "/assets/images/welcomev2/logo.png"}`}
                />
                <CloseOutlined className="gx-menu-close" style={{ fontSize: "20px", cursor: "pointer" }} />
              </div>

              <li className="gx-menu__item menu__dropdown">
                <Link className="gx-menu__link" to="#">
                  Products
                  <DownOutlined style={{ fontSize: "12px", cursor: "pointer", marginTop: '2px' }} className="gx-ml-2" />
                </Link>
                <div className="submenu megamenu__normal">
                  <ul className="submenu__list">
                    <li>
                      <Link to="/sentinel">Sentinel</Link>
                    </li>
                    <li>
                      <Link to="/overseer">Overseer</Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="gx-menu__item">
                <Link className="gx-menu__link" to="/career">
                  Career
                </Link>
              </li>
              <li className="gx-menu__item">
                <Link className="gx-menu__link" to="/blog">
                  Blog
                </Link>
              </li>
              <li className="gx-menu__item">
                <Link className="gx-menu__link" to="/pricing">
                  Pricing
                </Link>
              </li>
              <li className="gx-menu__item">
                <Link className="gx-menu__link" to="/ai">
                  Edutechs AI
                </Link>
              </li>
              <li className="gx-menu__item">
                <Link className="gx-menu__link" to="/contact">
                  Contact
                </Link>
              </li>

              <div className="gx-mobile-menu-btn-wrapper gx-d-flex">
                <Button onClick={() => history.push('/signin')} className="gx-auth-btn gx-login-btn">Login</Button>
                <Button onClick={() => history.push('/signup')} className="gx-auth-btn gx-signup-btn">Signup</Button>
              </div>
            </ul>
          </div>
        </section>
        <section className="gx-navbar__right">
          <span className="gx-language">
            <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={languageMenu()} trigger="click">
              <span className="gx-pointer gx-flex-row gx-align-items-center">
                <i className={`flag flag-24 flag-${locale.icon}`} />
              </span>
            </Popover>
          </span>
          <span style={{ marginTop: "3px" }}>
            <DarkLightMode />
          </span>

          <div className="gx-auth-btn-wrapper gx-d-flex">
            <Button onClick={() => history.push('/signin')} className="gx-mb-0 gx-auth-btn gx-login-btn">Login</Button>
            <Button onClick={() => history.push('/signup')} className="gx-mb-0 gx-auth-btn gx-signup-btn">Signup</Button>
          </div>
        </section>
      </nav>
    </header>
  );
};

export default WelcomeHeader;
