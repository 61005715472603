import {useEffect} from "react";
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import {Route, Switch} from "react-router-dom";
import "assets/vendors/style";
import configureStore, { history } from './appRedux/store';
import "./firebase/firebase";
import App from "./containers/App/index";

const store = configureStore({});

export type RootState       = ReturnType<typeof store.getState>
export type AppDispatch     = typeof store.dispatch




const NextApp = () => {

    useEffect(() => {
        window.process = {
          ...window.process,
        };
    }, []);

    return (
        <Provider store={store} >
            <ConnectedRouter history={history}>
                <Switch>
                    <Route path="/" component={App}/>
                </Switch>
            </ConnectedRouter>
        </Provider>

    )
}



export default NextApp;