import React from 'react';
import { Link } from 'react-router-dom';

const CaseStudiesSection = () => {
    return (
        <div className="gx-case-studies-section gx-section-padding gx-extra-py">
            <div className="gx-landing-container">
                <div className="gx-news-header">
                    <h2>Case Study</h2>
                   
                </div>
                <div className="gx-case-study-card">
                    <div className="gx-case-image-container">
                        <img
                            src= "/assets/images/welcomev2/case-logo.png"
                            alt="Edbase Logo"
                            className="gx-case-main-logo"
                        />
                        <img
                            src= "/assets/images/welcomev2/person.png"
                            alt="Person"
                            className="gx-person-avatar"
                        />
                    </div>
                    <div className="gx-case-study-content">
                        <h3>
                            Ensuring a smooth onboarding experience for a large institution like
                            Edbase
                        </h3>
                        <p>
                            Edbase, a prominent after-school education institute with over
                            2100+ active students, 20+ teachers, and numerous stakeholders,
                            required an innovative approach to transition smoothly from manual
                            processes to using Edutech’s platform.
                        </p>
                        <Link className="gx-view-button">
                            View Detailed Case Study
                        </Link>
                    </div>

                </div>
            </div>

        </div>
    );
};

export default CaseStudiesSection;
